import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../Images/noxonic image.jpg'; // Adjust the path if necessary
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faInstagram, faXTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'; // Import the CSS for custom styles

const Footer = () => {
  return (
    <div className="footer p-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img src={logo} width="50%" alt="Mezash Logo" />
            <p className="p-2 mezash-footer mt-2">
              Nexonix Solutions is a leading IT company dedicated to transforming businesses through cutting-edge software solutions. We are a team of passionate and skilled professionals who bring together expertise in cloud technology, data analytics, BI, and AI. Our primary focus is on understanding our clients' unique challenges and crafting tailored solutions that propel them towards digital excellence.
            </p>
          </div>
          <div className="col-md-4">
            <h2>Pages</h2>
            <div className="pages-list">
              <div className="container-fluid">
                <div className="row g-1">
                  <div className="col p-2"><Link to="/">Home</Link></div>
                  <div className="col p-2"><Link to="/About">About</Link></div>
                </div>
                <div className="row g-1">
                  <div className="col p-2"><Link to="/Services">Services</Link></div>
                  <div className="col p-2"><Link to="/Contactus">Contact</Link></div>
                </div>
                <div className="row g-1">
                  <div className="col p-2"><Link className="fw-semibold" to="/PrivacyPolicy">Privacy Policy</Link></div>
                </div>
                <div className="row g-1">
                  <div className="col p-2"><Link className="fw-semibold" to="/TermsAndConditions">Terms and Conditions</Link></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <h2>Location</h2>
            <div className="d-flex mezash-location p-2">
              <FontAwesomeIcon icon={faLocationDot} className="p-2 mt-2 location-icon" />
              <p className="p-2 mx-2">GH-03, Greater Noida W Rd, Sector 2, Greater Noida, Uttar Pradesh 201308</p>
            </div>
            <div className="d-flex mezash-location p-2">
  <FontAwesomeIcon icon={faPhone} className="p-2 mt-2 location-icon text-white" />
  <p className="p-2 mx-2 text-white">
    <a href="tel:+918826937627" className="text-decoration-none text-white">+91 88269 37627</a> 
  </p>
</div>
<div className="d-flex mezash-location p-2">
  <FontAwesomeIcon icon={faEnvelope} className="p-2 mt-2 location-icon text-white" />
  <p className="p-2 mx-2 text-white">
    <a href="mailto:office@nexonixsolution.com" className="text-decoration-none text-white">office@nexonixsolution.com</a> 
  </p>
</div>
            <div className="connect-us-container">
              <h2>Connect Us</h2>
              <a href="mailto:office@nexonixsolution.com"><FontAwesomeIcon icon={faEnvelope} className="p-2 social-media mail" /></a>
              {/* <a href="" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} className="p-2 social-media linkedin" /></a> */}
              <a href="https://www.instagram.com/nexonixsolution/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} className="p-2 social-media instagram" /></a>
              <a href="https://x.com/nexonixsolution" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faXTwitter} className="p-2 social-media twitter" /></a>
              <a href="https://m.facebook.com/profile.php?id=61566627040808" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook} className="p-2 social-media facebook" /></a>
            </div>
          </div>
        </div>
        <hr />
        <p className="text-center text-white"> {/* Updated color to white */}
          © 2024 <a className="fw-semibold text-white" href="https://www.nexonixsolution.com/" target="_blank">NEXONIX SOLUTION PRIVATE LIMITED</a>. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
